import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { map, take } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class JobsService {
	public jobsList: AngularFirestoreCollection<any>;
	public userId: string;

	constructor(public afAuth: AngularFireAuth, private firestore: AngularFirestore) {
		this.afAuth.authState.subscribe(user => {
			this.userId = user.uid;
			console.log("TCL: JobsService -> this.userId", this.userId);
			this.jobsList = this.firestore.collection(`/crewMembers/${user.uid}/jobs`);
		});
	}

	getJobList(): any {
		// return this.jobsList;
		// get me all jobs and shifts where crewId === userId
		return this.firestore
			.collection<any>(`/crewMembers/${this.userId}/jobs/`, ref => ref.orderBy("jobNumber", "asc").where("active", "==", true))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data();
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	getCrewJobs(userId): any {
		return this.firestore
			.collection<any>(`plannedDays`, ref =>
				ref
					.orderBy("date", "asc")
					.where("crewId", "==", userId)
					.where("dayConfirmed", "==", true)
					.where("jobActive", "==", true)
					.where("active", "==", true)
			)
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data();
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	getLeadGuyInfo(jobId) {
		return this.firestore.collection('plannedDays', ref => ref.where('jobId', '==', jobId).orderBy("date", "asc")).valueChanges();
	}

	getJobCrewMembers(jobId): any {
		return this.firestore
			.collection<any>(`/jobs/${jobId}/crewMembers/`, ref => ref.orderBy("firstname", "asc"))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data();
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	getCrewMembersDaysForJob(jobId): any {
		return this.firestore
			.collection<any>(`plannedDays`, ref =>
				ref
					.orderBy("date", "asc")
					.where("confirmed", "==", true)
					.where("crewId", "==", this.userId)
					.where("jobId", "==", jobId)
			)
			.valueChanges();
	}

	confirmJob(jobId, plannedDayId) {
		return this.firestore
			.doc(`crewMembers/${this.userId}/jobs/${jobId}`)
			.set({ confirmed: true }, {merge: true})
			.then(() => {
				return this.firestore
					.doc(`jobs/${jobId}/crewMembers/${this.userId}`)
					.set({ confirmed: true }, {merge: true})
					.then(() => {
						return this.confirmJobDay(plannedDayId);
					});
			});
	}

	confirmJobDay(id) {
		return this.firestore.doc(`plannedDays/${id}`).set({ confirmed: true }, {merge: true});
	}

	declineJob(jobId, confirmJobDayId) {
		return this.firestore
			.doc(`crewMembers/${this.userId}/jobs/${jobId}`)
			.set({ denied: true }, {merge: true})
			.then(() => {
				return this.firestore
					.doc(`jobs/${jobId}/crewMembers/${this.userId}`)
					.set({ denied: true }, {merge: true})
					.then(() => {
						return this.firestore.doc(`plannedDays/${confirmJobDayId}`).set({ denied: true }, {merge: true});
					});
			});
	}

	getJob(jobId: string): any {
		return this.firestore.doc(`/jobs/${jobId}`).valueChanges();
	}

	fetchConfirmedCrew(jobId) {
		return this.firestore
			.collection<any>(`/jobs/${jobId}/crewMembers/`, ref => ref.where("confirmed", "==", true))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data();
						data.userId = this.userId;
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	completeJob(jobId) {
		return this.firestore.doc(`/jobs/${jobId}`).set({ status: "Completed" }, {merge: true});
	}
}
